import React from 'react'
import ReactDOM from 'react-dom/client'
import { WagmiConfig, configureChains, createClient, allChains } from 'wagmi'
import { infuraProvider } from 'wagmi/providers/infura'
import {
  connectorsForWallets,
  RainbowKitProvider,
  wallet,
  midnightTheme,
} from '@rainbow-me/rainbowkit'
import '../node_modules/@rainbow-me/rainbowkit/dist/index.css'
import { AppProvider } from './context/AppContext'
import App from './App'
import './scss/main.scss'

import { Web3AuthConnector } from '@web3auth/web3auth-wagmi-connector'

const { chains, provider } = configureChains(
  [allChains[0]],
  [
    infuraProvider({
      apiKey: process.env.REACT_APP_INFURA_API_KEY,
      priority: 0,
      weight: 1,
    }),
  ]
)
export const web3authConnector = new Web3AuthConnector({
  chains,
  options: {
    enableLogging: true,
    clientId: process.env.REACT_APP_WEB3AUTH_CLIENT_ID,
    chainNamespace: 'eip155',
    chainId: '0x1',
    rpcTarget: 'https://mainnet.infura.io/v3/776218ac4734478c90191dde8cae483c',
    uiConfig: {
      theme: 'dark',
      appLogo: 'https://user.firstbatch.xyz/logo512.png',
      loginMethodConfig: {
        google: {
          name: 'google',
          showOnModal: true,
        },
        facebook: { showOnModal: false },
        github: { showOnModal: false },
        reddit: { showOnModal: false },
        linkedin: { showOnModal: false },
        wechat: { showOnModal: false },
        twitch: { showOnModal: false },
        discord: { showOnModal: false },
        apple: { showOnModal: false },
        twitter: { showOnModal: false },
        line: { showOnModal: false },
        kakao: { showOnModal: false },
        weibo: { showOnModal: false },
        email_passwordless: { showOnModal: false },
      },
    },
  },
})

export const rainbowWeb3AuthConnector = ({ chains }) => ({
  id: 'web3auth',
  name: 'Google',
  iconUrl: 'https://minter-v3.vercel.app/google.png',
  iconBackground: '#fff',
  createConnector: () => {
    const connector = web3authConnector
    return {
      connector,
    }
  },
})

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [rainbowWeb3AuthConnector({ chains })],
  },
  {
    groupName: 'If you have a wallet',
    wallets: [wallet.metaMask({ chains })],
  },
])

const WagmiClient = createClient({
  autoConnect: false,
  connectors,
  provider,
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <WagmiConfig client={WagmiClient}>
      <RainbowKitProvider theme={midnightTheme()} chains={chains}>
        <AppProvider>
          <App />
        </AppProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
)
