// https://kth7zff1sg.execute-api.us-east-1.amazonaws.com/dev/ || https://api.firstbatch.xyz/
// https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/ || https://api2.firstbatch.xyz/
// https://0a8dia6t0c.execute-api.us-east-1.amazonaws.com/dev/ || https://api3.firstbatch.xyz/

export const setSessionURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/set_session'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/set_session'
}

export const checkUserURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/check_user'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/check_user'
}

export const getUserURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/get_user'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/get_user'
}

export const saveUserURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/save_user'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/save_user'
}

export const updateUserURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/update_user'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/update_user'
}

export const removeUserURL = () => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? 'https://api2.firstbatch.xyz/2/remove_user'
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        'https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/remove_user'
}

export const taskIdStatusURL = taskId => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? `https://api2.firstbatch.xyz/2/tasks/${taskId}?mode=mint`
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/tasks/${taskId}?mode=mint`
}

export const getGithubURL = idCommitment => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? `https://api2.firstbatch.xyz/2/login/github?callback_url=auth_github&commitment=${idCommitment}`
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/login/github?callback_url=auth_github&commitment=${idCommitment}`
}

export const getTwitterURL = idCommitment => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? `https://api2.firstbatch.xyz/2/twitter_auth?callback_url=persona-analysis/twitter&commitment=${idCommitment}`
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/twitter_auth?callback_url=persona-analysis/twitter&commitment=${idCommitment}`
}

export const getRedditURL = idCommitment => {
  return process.env.REACT_APP_ENDPOINT_TARGET === 'prod'
    ? `https://api2.firstbatch.xyz/2/auth_reddit?commitment=${idCommitment}`
    : process.env.REACT_APP_ENDPOINT_TARGET === 'test' &&
        `https://un3fr66hk5.execute-api.us-east-1.amazonaws.com/firstbatch-test-dev/2/auth_reddit?commitment=${idCommitment}`
}
