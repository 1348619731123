import { useContext } from 'react'
import { Puff } from 'react-loading-icons'
import AppContext from '../../context/AppContext'

export default function Authorization() {
  const { isPending, createIdComm, errMsg } = useContext(AppContext)

  return (
    <div className='authorizationContainer'>
      <div className='contentWrapper'>
        <div className='row'>
          <span>Welcome Anon!</span>
          <span>
            You're an <strong>early bird.</strong>
          </span>
          <div className='line'></div>
        </div>
        <div className='row'>
          <span>
            Create Your <span className='greenGradient'>FirstBatch ID</span>
          </span>
          <span className='smolText'>
            We're using a signed message as a seed to create your ID. FirstBatch
            uses zero-knowledge cryptography to preserve privacy.
          </span>
        </div>
        <button
          onClick={() => {
            !isPending && createIdComm({ type: 'sign' })
          }}>
          {isPending ? <Puff /> : 'Start'}
        </button>
        {errMsg && <span className='errMsg'>{errMsg}</span>}
      </div>
    </div>
  )
}
