import { useState, useContext } from 'react'
import { SiTwitter, SiReddit } from 'react-icons/si'
import { Puff } from 'react-loading-icons'

import AppContext from '../../context/AppContext'
import {
  getTwitterURL,
  getRedditURL,
  getGithubURL,
} from '../../helpers/endpoints'

export default function Socials() {
  const { idComm, wConnector } = useContext(AppContext)
  const [clickedBtn, setClickedBtn] = useState(null)

  const clickHandler = selectedSocial => {
    if (clickedBtn !== null) return
    let endpoint
    localStorage.setItem('usedConnector', wConnector)
    setClickedBtn(selectedSocial)

    selectedSocial === 'twitter'
      ? (endpoint = getTwitterURL(idComm))
      : selectedSocial === 'reddit'
      ? (endpoint = getRedditURL(idComm))
      : selectedSocial === 'github' && (endpoint = getGithubURL(idComm))

    fetch(endpoint)
      .then(response => response.text())
      .then(data => {
        window.location.href = data
      })
      .catch(err => {
        console.log(`${selectedSocial}Connect err =>`, err)
        setClickedBtn(null)
      })
  }

  return (
    <div className='socialsContainer'>
      <div className='contentWrapper'>
        <div className='row'>
          <span className='greenGradient'>Personalize the Internet</span>
          <span>Attach Your Interests To Your ID</span>
          <div className='line'></div>
        </div>
        <div className='row socialIcons'>
          {clickedBtn === 'twitter' ? (
            <Puff />
          ) : (
            <SiTwitter
              className='socialBtn'
              onClick={() => clickHandler('twitter')}
            />
          )}
          {clickedBtn === 'reddit' ? (
            <Puff />
          ) : (
            <SiReddit
              className='socialBtn'
              onClick={() => clickHandler('reddit')}
            />
          )}
          {/* {clickedBtn === 'github' ? (
            <Puff />
          ) : (
            <SiGithub
              className='socialBtn'
              onClick={() => clickHandler('github')}
            />
          )} */}
        </div>
        <div className='row'>
          <span>Select A Social To Continue</span>
          <span className='smolText'>
            FirstBatch AI generates interests based on your digital footprint
            and attaches to your ID without linking it to your wallet or email.
          </span>
        </div>
      </div>
    </div>
  )
}
