import { BrowserRouter, Routes, Route } from 'react-router-dom'
import PersonaAnalysis from './Components/PersonaAnalysis'
import NotFound from './Components/NotFound'

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<PersonaAnalysis />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}
