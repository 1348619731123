import { useContext } from 'react'
import AppContext from '../../context/AppContext'
import Layout from '../Layout'
import Authorization from './Authorization'
import Analysis from './Analysis'
import Socials from './Socials'

export default function PersonaAnalysis() {
  const { idComm, doesUserExists } = useContext(AppContext)

  return (
    <Layout>
      {idComm === null ? (
        <Authorization />
      ) : doesUserExists === true ? (
        <Analysis />
      ) : (
        doesUserExists === false && <Socials />
      )}
    </Layout>
  )
}
