import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSwitchNetwork } from 'wagmi'
import { Puff } from 'react-loading-icons'
import CustomRKButton from '../customRKButton'

export default function UserWarning({ type }) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const tID = queryParams.get('task_id')
  const [taskId, setTaskID] = useState(null)

  useEffect(() => {
    setTaskID(tID)
  }, [tID, taskId])

  const network = useSwitchNetwork({
    chainId: 1,
  })

  return (
    <>
      {type === 'notAuthorized' ? (
        <div className='userWarningContainer'>
          <div className='title'>
            <img src='/logo.png' alt='FirstBatch Logo' />
            <span>FirstBatch</span>
          </div>
          {taskId ? (
            <div className='text'>
              Welcome! Connect to complete your analysis.
            </div>
          ) : (
            <div className='text'>Welcome! Connect to continue.</div>
          )}
          <CustomRKButton />
        </div>
      ) : type === 'wrongChain' ? (
        <>
          <div className='userWarningContainer'>
            <div className='title'>
              <img src='/logo.png' alt='FirstBatch Logo' />
              <span>FirstBatch</span>
            </div>
            <div className='text'>
              Connected to wrong chain, please switch to Polygon.
            </div>
            <button onClick={() => network.switchNetwork()}>
              Switch Network
            </button>
          </div>
        </>
      ) : type === 'usedAcc' ? (
        <div className='userWarningContainer'>
          <div className='title'>
            <img src='/logo.png' alt='FirstBatch Logo' />
            <span>FirstBatch</span>
          </div>
          <div className='text'>Account you tried to connect is used.</div>
        </div>
      ) : type === 'taskIdPending' ? (
        <div className='userWarningContainer'>
          <Puff />
        </div>
      ) : type === 'init' ? (
        <div className='userWarningContainer'>
          <div className='title'>
            <img src='/logo.png' alt='FirstBatch Logo' />
            <span>FirstBatch</span>
          </div>
          <div className='text'>Initializing</div>
          <Puff />
        </div>
      ) : (
        type === 'wrongConnector' && (
          <div className='userWarningContainer'>
            <div className='title'>
              <img src='/logo.png' alt='FirstBatch Logo' />
              <span>FirstBatch</span>
            </div>
            <div className='text'>Please use the same account next time.</div>
          </div>
        )
      )}
    </>
  )
}
