import { createContext, useState, useEffect } from 'react'
import { Strategy, ZkIdentity } from '@zk-kit/identity'
import {
  useConnect,
  useAccount,
  useDisconnect,
  useNetwork,
  useSignMessage,
} from 'wagmi'

import {
  setSessionURL,
  checkUserURL,
  taskIdStatusURL,
  saveUserURL,
  getUserURL,
  removeUserURL,
  updateUserURL,
} from '../helpers/endpoints'

const isLogsActive = false // process.env.NODE_ENV === 'development'

const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const { connect, connectors } = useConnect({
    chainId: 1,
  })
  const account = useAccount({
    onConnect({ connector }) {
      setWChain(connector.chains[0].id)
    },
  })
  const signMessage = useSignMessage({
    message: `Creating ZK Identity with ${account?.address}`,
    onSuccess(data) {
      isLogsActive && console.log('Success', data)
      createIdComm({ type: 'create', sig: data })
    },
    onError(error) {
      console.log('Error', error)
      setIsPending(false)
    },
  })
  const network = useNetwork()
  const { disconnect } = useDisconnect({
    onSuccess() {
      isLogsActive && console.log('disconnected')
      setIdComm(null)
    },
  })
  const [wConnector, setWConnector] = useState(null)
  const [wChain, setWChain] = useState(null)
  const [idComm, setIdComm] = useState(null)
  const [doesUserExists, setDoesUserExists] = useState(false)
  const [errMsg, setErrMsg] = useState(null)
  const [isAccUsed, setIsAccUsed] = useState(false)
  const [userResults, setUserResults] = useState(null)
  const [isWrongConnector, setIsWrongConnector] = useState(false)

  const [isPending, setIsPending] = useState(false)
  const [isPendingTaskId, setIsPendingTaskId] = useState(false)

  // useEffect(() => {
  //   console.log('wChain =>', wChain)
  // }, [wChain])

  useEffect(() => {
    if (account?.connector?.id) {
      setWConnector(account.connector.id)
    }
  }, [account])

  useEffect(() => {
    setWChain(network.chain?.id)
  }, [network])

  const createIdComm = args => {
    const { type } = args
    if (type === 'sign') {
      setIsPending(true)
      signMessage.signMessage()
    } else if (type === 'create') {
      const { sig } = args
      const identity = new ZkIdentity(Strategy.MESSAGE, sig)
      const identityCommitment = identity.genIdentityCommitment().toString()
      createSession({ identityCommitment, sig })
    }
  }

  const createSession = async args => {
    isLogsActive && console.log('createSession invoked wiht =>', args)

    const msgBuffer = new TextEncoder().encode(args.identityCommitment)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const h_comm = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    const sessionId = await fetch(setSessionURL(), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ sig: args.sig, wid: account?.address, h_comm }),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('createSession res =>', data)
        return data.message.success
          ? data.message.session_id
          : data.message.success
      })
      .catch(err => {
        console.log('createSession err =>', err)
        return false
      })

    if (sessionId !== false) {
      localStorage.setItem('session', sessionId)
      checkUserState({
        id_commitment: args.identityCommitment,
        invokedFrom: 'createSession',
      })
    }
  }

  const checkUserState = async args => {
    isLogsActive && console.log('checkUserState invoked with =>', args)

    const { id_commitment, invokedFrom, results } = args

    const userState = await fetch(checkUserURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('session'),
      },
      body: JSON.stringify({ id_commitment }),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('checkUserState res =>', data)
        return data.message
      })
      .catch(err => {
        console.log('checkUserState err =>', err)
        return null
      })

    if (invokedFrom === 'taskIdInterval') {
      userState
        ? updateUser({ id_commitment, responseID: results.task_id })
        : saveUser({ results, id_commitment })
    } else if (userState === false) {
      setDoesUserExists(userState)
      setIdComm(id_commitment, id_commitment)
      setIsPending(false)
    } else if (userState === true) {
      getUser({ id_commitment })
    } else {
      setIsPending(false)
      setErr(
        "FirstBatch API's cannot be accessed at the moment, please try again later."
      )
    }
  }

  const taskIdInterval = args => {
    if (localStorage.getItem('usedConnector') === args.connector) {
      setIsPendingTaskId(true)
      const interval = setInterval(() => {
        intervalRequest(args)
      }, 1000 * 5)

      const intervalRequest = async args => {
        fetch(taskIdStatusURL(args.taskId))
          .then(response => response.json())
          .then(data => {
            isLogsActive && console.log('taskIdResponse =>', data)

            if (
              data.task_status === 'complete' &&
              data.task_result.code === 200
            ) {
              isLogsActive && console.log('handle succ here')

              clearInterval(interval)
              checkUserState({
                results: data,
                id_commitment: data.task_result.message.id_commitment,
                invokedFrom: 'taskIdInterval',
              })
            } else if (
              data.task_result.message === 'This account is already used!'
            ) {
              isLogsActive && console.log('handle acc is already used here')

              clearInterval(interval)
              setIsPendingTaskId(false)
              setIsAccUsed(true)
            }
          })
          .catch(err => {
            clearInterval(interval)
            setIsPendingTaskId(false)
            console.log('taskId interval err =>', err)
          })
      }
    } else {
      setIsWrongConnector(true)
      return
    }
  }

  const getUser = args => {
    isLogsActive && console.log('getUser invoked with')

    fetch(getUserURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('session'),
      },
      body: JSON.stringify({ id_commitment: args.id_commitment }),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('checkUserState res =>', data)
        setUserResults(data)
        setDoesUserExists(true)
        setIdComm(args.id_commitment)
        setIsPending(false)
        setIsPendingTaskId(false)
      })
      .catch(err => {
        console.log('checkUserState err =>', err)
        return null
      })
  }

  const saveUser = args => {
    isLogsActive && console.log('saveUser invoked with =>', args)

    const { results, id_commitment } = args
    const socialPlatform = results.task_result.message.media
    const socialId = results.task_result.message.media_id
    const bodyObj = {
      identity: {
        id_commitment,
        last_login_date: Date.now(),
        media_ids: {
          twitter: socialPlatform === 'twitter' ? socialId : '',
          reddit: socialPlatform === 'reddit' ? socialId : '',
          github: socialPlatform === 'github' ? socialId : '',
        },
      },
      responseID: results.task_id,
      id_commitment,
    }

    fetch(saveUserURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('session'),
      },
      body: JSON.stringify(bodyObj),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('saveUser res =>', data)
        getUser({ id_commitment })
      })
      .catch(err => {
        console.log('saveUser err =>', err)
      })
  }

  const updateUser = args => {
    console.log('updateUser invoked with =>', args)

    const { id_commitment, responseID } = args

    fetch(updateUserURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('session'),
      },
      body: JSON.stringify({ id_commitment, responseID }),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('updateUser res =>', data)
        getUser({ id_commitment })
      })
      .catch(err => {
        console.log('updateUser err =>', err)
        return null
      })
  }

  const removeUser = () => {
    setIsPending(true)
    fetch(removeUserURL(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('session'),
      },
      body: JSON.stringify({ id_commitment: idComm }),
    })
      .then(response => response.json())
      .then(data => {
        isLogsActive && console.log('burnUser res =>', data)
        setIsPending(false)

        if (data.message === 'User successfully removed!') {
          setDoesUserExists(false)
          setUserResults(null)
        }
      })
      .catch(err => {
        setIsPending(false)
        console.log('burnUser err =>', err)
      })
  }

  const setErr = msg => {
    setErrMsg(msg)
    setTimeout(() => {
      setErrMsg(null)
    }, 5000)
  }

  return (
    <AppContext.Provider
      value={{
        connect,
        connectors,
        account,
        disconnect,
        wConnector,
        wChain,
        idComm,
        setIdComm,
        createIdComm,
        isPending,
        doesUserExists,
        errMsg,
        taskIdInterval,
        isAccUsed,
        isPendingTaskId,
        userResults,
        removeUser,
        isWrongConnector,
      }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
