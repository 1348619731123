import { useState, useEffect, useContext } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { SiTwitter, SiReddit } from 'react-icons/si'
import { Puff } from 'react-loading-icons'
import AppContext from '../../context/AppContext'
import {
  getTwitterURL,
  getRedditURL,
  getGithubURL,
} from '../../helpers/endpoints'

const personhoodRanks = [0, 1, 2, 3, 4]

export default function Analysis() {
  const { userResults, idComm, isPending, removeUser, wConnector } =
    useContext(AppContext)
  const [interestArr1, setInterestArr1] = useState([])
  const [interestArr2, setInterestArr2] = useState([])
  const [userRank, setUserRank] = useState(0)
  const [mediaStates, setMediaStates] = useState({
    twitter: false,
    reddit: false,
    github: false,
  })
  const [focusedInterest, setFocusedInterest] = useState(null)
  const [clickedBtn, setClickedBtn] = useState(null)

  useEffect(() => {
    setUserRank(userResults?.interests?.message?.rank)
    setMediaStates(userResults?.media_states?.message?.media_states)
    const interests = userResults.interests.message.interests
    let arr1 = []
    let arr2 = []
    interests &&
      interests.forEach((interest, index) => {
        index % 2 === 0 ? arr1.push(interest) : arr2.push(interest)
      })
    setInterestArr1(arr1)
    setInterestArr2(arr2)
  }, [userResults])

  const clickHandler = selectedSocial => {
    if (clickedBtn !== null || isPending) return
    setClickedBtn(selectedSocial)
    localStorage.setItem('usedConnector', wConnector)

    let endpoint

    selectedSocial === 'twitter'
      ? (endpoint = getTwitterURL(idComm))
      : selectedSocial === 'reddit'
      ? (endpoint = getRedditURL(idComm))
      : selectedSocial === 'github' && (endpoint = getGithubURL(idComm))

    fetch(endpoint)
      .then(response => response.text())
      .then(data => {
        window.location.href = data
      })
      .catch(err => {
        console.log(`${selectedSocial}Connect err =>`, err)
        setClickedBtn(null)
      })
  }

  return (
    <div className='analysisContainer'>
      <span className='greenGradient mainTitle'>Your Persona</span>
      <div className='personaContainer'>
        <div className='top'>
          <span className='title'>Interests</span>
          <span className='text'>
            Below FirstBatch lists your persona traits and interests generated
            based on your footprint.
            <div className='line'></div>
          </span>
        </div>
        <div className='bottom'>
          <div className='left'>
            {interestArr1.length !== 0 &&
              interestArr1.map(interest => {
                return (
                  <div
                    key={`interest${interest.traitName}`}
                    className={`interests ${
                      focusedInterest === interest ? `activeInterest` : ''
                    }`}>
                    <div
                      className='interestLabel'
                      onClick={() => {
                        focusedInterest === interest
                          ? setFocusedInterest(null)
                          : setFocusedInterest(interest)
                      }}>
                      {interest.traitName}
                      <MdKeyboardArrowDown />
                      <div className='line'></div>
                    </div>
                    <div className='traits'>
                      {interest.interests.map(trait => {
                        return (
                          <div className='trait' key={`trait${trait}`}>
                            {trait}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
          </div>
          <div className='right'>
            {interestArr2.length !== 0 &&
              interestArr2.map(interest => (
                <div
                  key={`interest${interest.traitName}`}
                  className={`interests ${
                    focusedInterest === interest ? `activeInterest` : ''
                  }`}>
                  <div
                    className='interestLabel'
                    onClick={() => {
                      focusedInterest === interest
                        ? setFocusedInterest(null)
                        : setFocusedInterest(interest)
                    }}>
                    {interest.traitName}
                    <MdKeyboardArrowDown />
                    <div className='line'></div>
                  </div>
                  <div className='traits'>
                    {interest.interests.map(trait => {
                      return (
                        <div className='trait' key={`trait${trait}`}>
                          {trait}
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className='personhoodContainer'>
        <div className='top'>
          <span className='title'>Personhood</span>
          <span className='text'>
            Indicates how organic your interest data
            <div className='line'></div>
          </span>
        </div>
        <div className='bottom'>
          {personhoodRanks.map(rank => {
            return (
              <div
                className={`rankBox ${userRank > rank ? 'active' : ''}`}
                key={`rankbox${rank}`}
              />
            )
          })}
        </div>
      </div>
      <div className='connectedAccounts'>
        <div className='top'>
          <span className='title'>Connected accounts</span>
          <span className='text'>
            List of socials used to create your interests. Every connection
            enriches your identity, making it more accurate
          </span>
          <div className='line'></div>
        </div>
        <div className='bottom'>
          {clickedBtn === 'twitter' ? (
            <Puff />
          ) : (
            <SiTwitter
              className={`${
                mediaStates.twitter ? 'activeLogo' : 'inactiveLogo'
              }`}
              onClick={() => clickHandler('twitter')}
            />
          )}
          {clickedBtn === 'reddit' ? (
            <Puff />
          ) : (
            <SiReddit
              className={`${
                mediaStates.reddit ? 'activeLogo' : 'inactiveLogo'
              }`}
              onClick={() => clickHandler('reddit')}
            />
          )}
          {/* {clickedBtn === 'github' ? (
            <Puff />
          ) : (
            <SiGithub
              className={`${
                mediaStates.github ? 'activeLogo' : 'inactiveLogo'
              }`}
              onClick={() => clickHandler('github')}
            />
          )} */}
        </div>
      </div>
      <button
        onClick={() => {
          !isPending && removeUser()
        }}>
        {isPending ? <Puff /> : 'Delete ID'}
      </button>
    </div>
  )
}
